import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import SeoPlugin from 'gatsby-plugin-wpgraphql-seo'
import ProgramHandbook from '../components/ProgramHandbook/ProgramHandbook'
import Seo from '../components/Seo'
import Wysiwyg from '../components/Wysiwyg'
import Container from '../components/Container'
import Button from '../components/Button'

const Excerpt = styled(Wysiwyg)`
  * {
    ${tw`leading-7 text-sm text-black`}
  }
`
const Handbook = ({ data }) => (
  <>
    {/* <Seo title="Program Handbook" /> */}
    <SeoPlugin post={data} title="Program Handbook" />
    <ProgramHandbook data={data.allWpParentHandbook.edges}>
      {/* <Container variant="wide" tw="py-10"> */}
      <div>
        <h2>Program Handbook</h2>
        <p>
          Our Program Handbook for families contains detailed information on all aspects of our child care programs at Umbrella. If you have
          any questions about Umbrella's policies, please contact your centre's Supervisor or email{' '}
          <a href="mailto:info@umbrellafamily.com">info@umbrellafamily.com</a>
        </p>
      </div>
      <div>
        {data.allWpParentHandbook.edges.map(handbook => {
          const parsedExcerpt = `${handbook.node.content?.substring(0, 109)}...`
          const featuredImage = getImage(handbook.node.featuredImage?.node.gatsbyImage)

          return (
            <div tw="flex py-7 items-center border-b border-purple/20 phablet:flex-col">
              <div tw="w-1/3 min-w-[250px] space-x-5 phablet:mb-5 phablet:w-full">
                {handbook.node.featuredImage ? (
                  <GatsbyImage
                    tw="rounded-3xl mb-5 mr-5 max-h-[140px] my-auto phablet:max-h-full"
                    css={css`
                      aspect-ratio: 16/9;
                      box-shadow: 4px 4px 20px 5px #81318f40;
                    `}
                    image={featuredImage}
                    alt={handbook.node.featuredImage.node.altText}
                    imgStyle={{ borderRadius: `1.5rem` }}
                  />
                ) : (
                  <StaticImage
                    src="../assets/images/featured-image-default.jpg"
                    alt="default image"
                    tw="rounded-3xl mb-5 mr-5 max-h-[140px] my-auto"
                    css={css`
                      aspect-ratio: 16/9;
                      box-shadow: 4px 4px 20px 5px #81318f40;
                    `}
                    imgStyle={{ borderRadius: `1.5rem` }}
                  />
                )}
              </div>
              <div tw="w-2/3 phablet:w-full">
                <h3 tw="mt-0 text-black text-[2rem]">{handbook.node.title}</h3>
                {/* <Excerpt>{parsedExcerpt}</Excerpt> */}
                <Button
                  variant="text"
                  size="small"
                  href={handbook.node.uri}
                  iconLibrary="solid"
                  icon="ArrowLongRightIcon"
                  iconStyle={tw`h-3 w-3 ml-2 inline-block`}
                  iconPosition="after"
                >
                  Read More
                </Button>
              </div>
            </div>
          )
        })}
      </div>
      {/* </Container> */}
    </ProgramHandbook>
  </>
)

export default Handbook

export const pageQuery = graphql`
  query {
    allWpParentHandbook(sort: { fields: menuOrder, order: ASC }) {
      edges {
        node {
          id
          parentId
          uri
          slug
          title
          content
          featuredImage {
            node {
              altText
              gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 500)
            }
          }
          seo {
            title
            metaDesc
            readingTime
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              sourceUrl
              srcSet
            }
            twitterTitle
            twitterDescription
            twitterImage {
              altText
              sourceUrl
              srcSet
            }
            canonical
            cornerstone
            schema {
              articleType
              pageType
              raw
            }
            breadcrumbs {
              text
              url
            }
          }
        }
      }
    }
  }
`
